.slick-slider {
  &.three-d {
    .slick-list {
      @apply overflow-visible;
      .slick-track {
        @apply min-tablet:py-12 py-17;
      }
      .slick-slide {
        @apply relative z-10 ease-in-out transition duration-300;

        .img-container {
          @apply outline-none ease-in-out transition duration-300;
          perspective-origin: center;
          transform: perspective(900px) translate(-2.5rem) rotateY(35deg);
        }
        &:not(.slick-current) {
          .content-container {
            @apply opacity-0;
          }
        }

        &.slick-current {
          @apply relative z-50;
          filter: drop-shadow(-11.6008px 23.2015px 38.6692px rgba(17, 31, 61, 0.2));

          .img-container {
            @apply relative  mx-4;
            &:after {
              content: '';
              @apply rounded-2xl absolute left-0 top-0 right-0 bottom-0;
              background: linear-gradient(75.32deg, rgba(0, 0, 0, 0.5) 25.04%, rgba(0, 0, 0, 0) 108.74%);
            }
          }

          .img-container {
            perspective: 0px;
            transform: scaleX(1.5) scaleY(1.45) translate(-0.65rem);
            @media screen and (max-width: 600px) {
              transform: scale(1.4) translate(-8px);
            }
          }

          & ~ .slick-slide .img-container {
            transform: perspective(900px) translate(2.5rem) rotateY(-35deg);
          }
        }
      }
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
  &.classic {
    &.left-empty {
      .slick-list {
        @apply md:pl-5;
        .slick-slide {
          @apply md:-ml-8 px-4 md:px-0;
          .img-container {
            @apply md:mx-4;
            @media screen and (min-width: 768px) {
              width: 85% !important;
            }
          }
        }
      }
      .slick-prev.slick-disabled + .slick-list {
        @apply md:ml-4;
      }
    }
    &.full-center {
      .slick-list {
        .slick-slide {
          @apply -ml-8;
          .img-container {
            @apply mx-4;
            box-shadow: 3px 3px 5px rgb(51, 65, 85, 0.15);
            width: 85% !important;
          }
        }
      }
    }
    .slick-list {
      .slick-slide {
        @apply cursor-pointer;
        .img-container {
          @apply overflow-hidden rounded-2xl;
          box-shadow: 3px 3px 5px rgb(51, 65, 85, 0.15);
          @media screen and (min-width: 768px) {
            width: 85% !important;
          }
          &:after {
            content: '';
            @apply absolute left-0 top-0 rounded-6xl rounded-tl-none w-4/6 h-4/6 bg-black bg-opacity-60 blur-2xl;
          }
        }
        .details-container {
          @apply flex flex-col justify-between ease-in-out transition duration-300 overflow-auto opacity-0 invisible p-4 absolute z-51 right-0 top-0 bottom-0 w-8/12 bg-white dark:bg-dark-200 bg-opacity-90  rounded-2xl border-2 border-white dark:border-bordercolordark bg-clip-padding;
          backdrop-filter: blur(30px);
        }
        &:hover {
          .details-container {
            @apply visible opacity-100;
          }
        }
      }
    }
    .slick-arrow {
      @apply top-2/4 -translate-y-1/2;

      &.slick-disabled {
        @apply invisible opacity-0;
      }

      &.slick-next {
        @apply right-6;
      }

      &.slick-prev {
        @apply left-6 transform -translate-y-1/2 rotate-180;
      }
    }
  }

  &.classic,
  &.arrows {
    .slick-arrow {
      @apply absolute ease-in-out transition duration-300 bg-arrow-next dark:bg-arrow-next-dark bg-white dark:bg-dark-200 bg-opacity-90 bg-no-repeat bg-center bg-16 rounded-3xl text-zero w-14 h-14 sm:w-12 sm:h-12 z-52 border-2 border-white border-opacity-50 bg-clip-padding;
      backdrop-filter: blur(5px);
    }
  }

  &.arrows {
    &.vertical {
      .slick-slide {
        padding: 3px;
      }
      .slick-list {
        .slick-track {
          @apply static;
        }
      }

      @media screen and (max-width: 500px) {
        .slick-arrow {
          display: none !important;
        }
      }

      @media screen and (min-width: 500px) and (min-height: 700px) {
        .slick-arrow {
          @apply left-48;
          &.slick-prev {
            @apply -top-12 -rotate-90;
          }
          &.slick-next {
            @apply -bottom-12 rotate-90;
          }
        }
        @apply relative my-12;
        height: calc(100vh - 25.125rem) !important;
      }
    }
    &.narrow {
      .slick-arrow {
        @apply w-8 h-8 bg-8 top-0 border-bordercolor dark:border-bordercolorprimary;
        &.slick-prev {
          @apply rotate-180;
          left: calc(35% - 3rem);
        }
        &.slick-next {
          @apply right-1/4;
          left: calc(35%);
        }
        @media screen and (max-width: 767px) {
          &.slick-prev {
            left: unset;
            right: 3rem;
          }
          &.slick-next {
            right: 0;
            left: unset;
          }
        }
      }
    }
    &.center {
      .slick-arrow {
        @apply top-2/4 -translate-y-1/2 border-bordercolor dark:border-bordercolorprimary;

        &.slick-next {
          @apply right-6;
        }

        &.slick-prev {
          @apply left-6 transform -translate-y-1/2 rotate-180;
        }

        @media screen and (max-width: 767px) {
          &.slick-next {
            @apply -right-2;
          }

          &.slick-prev {
            @apply -left-2;
          }
        }
      }
    }
  }

  .slick-dots {
    @apply absolute bottom-2 min-tablet:bottom-0 left-0 block w-full p-0 m-0 text-center list-none;
    li {
      @apply relative inline-block my-0 mx-2.5 p-0 cursor-pointer ease-in-out transition duration-300 w-2 min-tablet:w-8;

      &.slick-active {
        button {
          @apply opacity-100 bg-primary-500 dark:bg-primary-500;
        }
      }
      button {
        @apply text-zero w-2 min-tablet:w-8 h-1.5 p-0 cursor-pointer bg-black dark:bg-dark-400 bg-opacity-10 rounded;

        &:before {
          @apply hidden;
        }

        &:hover,
        &:focus {
          @apply outline-none;
        }
      }
    }
  }
}
